import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"
import { defineNuxtPlugin } from "#app"
import {
  CameraFeatureFlag,
  ProjectFeatureFlag,
  Camera,
  NvrModel,
  UserFeatureFlag,
} from "@evercam/shared/types"

export default defineNuxtPlugin((app) => {
  const { isDev, route } = app.nuxt2Context
  const accountStore = useAccountStore()
  const isAdmin = () => {
    return (
      accountStore.isAdmin ||
      ["old-construction@evercam.io"].includes(accountStore.email)
    )
  }

  const isAiAdmin = () => {
    return [
      "ai@evercam.io",
      "radouane@evercam.io",
      "oussama@evercam.io",
      "ilias@evercam.io",
      "dhia@evercam.io",
      "asma@evercam.io",
      "sadmi@evercam.io",
      "hanene@evercam.io",
      "aqsa@evercam.io",
      "naima@evercam.io",
      "katia@evercam.io",
      "halim@evercam.io",
    ].includes(accountStore.email)
  }

  const isAnnotator = () => {
    return ["annotators@evercam.io"].includes(accountStore.email)
  }

  const isQA = () => {
    return ["younes.bensaada@evercam.io", "rami.moumeni@evercam.io"].includes(
      accountStore.email
    )
  }

  const isRestricted = (email = "") => {
    const userEmail = email || accountStore.email || ""

    return [
      "colm.fagan@vantage-dc.com",
      "Noel.Scanlon@vantage-dc.com",
      "noel.scanlon@vantage-dc.com",
      "michael.kennedy@vantage-dc.com",
      "Ruaidhri.Hehir@linesight.com",
      "ruaidhri.hehir@linesight.com",
      "nick.synott@vantage-dc.com",
      "megan.byrne@vantage-dc.com",
      "mchevillotte@vantage-dc.com",
      "carmen.jordaan@linesight.com",
      "Carmen.Jordaan@linesight.com",
      "eoghan.tangney@linesight.com",
      "edward@rathcaled.com",
    ].includes(userEmail)
  }

  const camera = {
    has: {
      bim: () => {
        return useCameraStore().selectedCamera?.featureFlags?.includes(
          CameraFeatureFlag.BimCompare
        )
      },
      gateReport: () => {
        return useCameraStore().selectedCamera?.featureFlags?.includes(
          CameraFeatureFlag.GateReport
        )
      },
      videoStream: () => {
        return useCameraStore().selectedCamera?.featureFlags?.includes(
          CameraFeatureFlag.VideoStream
        )
      },
      webrtc: () => {
        return useCameraStore().selectedCamera?.featureFlags?.includes(
          CameraFeatureFlag.WebRTC
        )
      },
      anpr: () => {
        return useCameraStore().selectedCamera?.featureFlags?.includes(
          CameraFeatureFlag.ANPR
        )
      },
      edgeVideoStreaming: (camera?: Camera) => {
        const selectedCamera = camera || useCameraStore().selectedCamera

        return (
          selectedCamera?.nvrModel === NvrModel.ExNvr &&
          selectedCamera?.featureFlags?.includes(CameraFeatureFlag.EdgeVideo)
        )
      },
    },
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const project = {
    has: {
      BIMView: (project = useProjectStore().selectedProject) => {
        return project?.featureFlags?.includes(ProjectFeatureFlag.BIM)
      },
      BIMViewIsITwin: () => {
        return useProjectStore().selectedProject?.featureFlags?.includes(
          ProjectFeatureFlag.BIM_ITWIN
        )
      },
      BIMViewIsForge: () => {
        return useProjectStore().selectedProject?.featureFlags?.includes(
          ProjectFeatureFlag.BIM_FORGE
        )
      },
      _360View: (project = useProjectStore().selectedProject) => {
        return project?.featureFlags?.includes(ProjectFeatureFlag._360View)
      },
      droneView: (project = useProjectStore().selectedProject) => {
        return project?.featureFlags?.includes(ProjectFeatureFlag.DRONE_VIEW)
      },
      gateReport: (project = useProjectStore().selectedProject) => {
        return project?.featureFlags?.includes(ProjectFeatureFlag.GATE_REPORT)
      },
      gateReportAutoVerify: () => {
        return useProjectStore().selectedProject?.featureFlags?.includes(
          ProjectFeatureFlag.GATE_REPORT_AUTO_VERIFY
        )
      },
      anprBasedGateReport: () => {
        return useProjectStore().selectedProject?.featureFlags?.includes(
          ProjectFeatureFlag.GATE_REPORT_ANPR_BASED
        )
      },
      fullEmptyFiltering: () => {
        const cameras = useProjectStore().selectedProject?.cameras as Camera[]

        return !!cameras.find((c) =>
          c.featureFlags?.includes(CameraFeatureFlag.FullEmptyFiltering)
        )
      },
      socialMediaSharing: () => {
        return useProjectStore().selectedProject?.featureFlags?.includes(
          ProjectFeatureFlag.MediaHubSharing
        )
      },
      weatherBYOD: () => {
        return useProjectStore()?.selectedProject?.exid === "barnh-agtox"
      },
      readOnlyPTZControls: () => {
        return ["intel-cryth"].includes(
          useProjectStore()?.selectedProject?.exid
        )
      },
      smartMilestones: () => {
        return useProjectStore().selectedProject?.featureFlags?.includes(
          ProjectFeatureFlag.SMART_MILESTONES
        )
      },
      copilotTimelapseReports: () => {
        return useProjectStore().selectedProjectCameras.some((c) =>
          c.featureFlags.includes(CameraFeatureFlag.CopilotTimelapseReport)
        )
      },
    },
  }

  const user = {
    is: {
      admin: isAdmin,
      aiAdmin: isAiAdmin,
      qa: isQA,
      restricted: isRestricted,
    },
    can: {
      access: {
        _360: () => {
          const routeProjectExid = camelizeKeys(route.params)?.projectExid
          const projectExid =
            useProjectStore().selectedProjectExid || routeProjectExid
          const userEmail = accountStore.email || ""

          const isUserFromPJH = //A special condition for a special case
            projectExid !== "treas-kxqjm" || // Either you're not from this project or
            (projectExid == "treas-kxqjm" && userEmail.includes("pjhegarty.ie")) //You are AND you belong to this company

          return isUserFromPJH && !isRestricted()
        },
        recordings: () => {
          const userEmail = accountStore.email || ""
          const routeCameraExid = camelizeKeys(route.params)?.cameraExid
          const cameraId =
            useCameraStore().selectedCameraExid || routeCameraExid

          const isRestrictedUsers = () => {
            return [
              "maurizio@platformpps.com.au",
              "wayne@platformpps.com.au",
              "adam@platformpps.com.au",
              "michael@platformpps.com.au",
              "matthew@platformpps.com.au",
            ].includes(userEmail)
          }
          const isCameraRestrictedUser = () => {
            return cameraId === "auham-zscnd" && isRestrictedUsers()
          }

          return !isCameraRestrictedUser() && !isRestricted()
        },
        timelapse: () => {
          return !isRestricted()
        },
        sharing: () => {
          return !isRestricted()
        },
        mediaHub: () => {
          return !isRestricted()
        },
        timeline: () => {
          return !isRestricted()
        },
        compare: () => {
          return !isRestricted()
        },
        bim: () => {
          const userEmail = accountStore.email || ""
          const isPriviledgedUser = () => {
            return [
              "cdoyle@bamcontractors.ie",
              "gbyrne@bamcontractors.ie",
              "affan@evercam.io",
              "bim@evercam.io",
              "ai@evercam.io",
            ].includes(userEmail)
          }
          const isFromPriviledgedCompany = () => {
            return ["intel.com"].some((companyName) =>
              userEmail.includes(companyName)
            )
          }

          return (
            (isAdmin() ||
              camera.has.bim() ||
              isPriviledgedUser() ||
              isFromPriviledgedCompany()) &&
            !isRestricted()
          )
        },
        company: () => {
          return (
            (isAdmin() ||
              useAccountStore().hasUserAccessToFeature(
                UserFeatureFlag.CompanyAdmin
              )) &&
            !isRestricted()
          )
        },
        gateReport: () => {
          const routeProjectExid = camelizeKeys(route.params)?.projectExid
          const projectExid =
            useProjectStore().selectedProjectExid || routeProjectExid
          const userEmail = accountStore.email || ""

          //By default, cameras not in this list will have Gate report accessible for all users (given that the feature flag is present)
          const userRestrictedProjects = {
            "siskp-hkfwx": [
              "a.hynes@sisk.ie",
              "vinnie@evercam.io",
              "c.moran@sisk.ie",
              "e.omeara@sisk.ie",
              "g.abernethy@sisk.ie",
              "m.stapleton@sisk.ie",
              "c.kelly@sisk.ie",
            ],
            "irish-qfuyr": [
              "ben@ashviewconsultants.com",
              "owen@urbansolutions.ie",
              "justyna@ashviewconsultants.com",
              "pclarke@ksn.ie",
              "gadam@ksn.ie",
              "owen.stynes@walls.ie",
              "eoin.redmond@walls.ie",
            ],
            "trave-frpgv": ["eoin@evercam.io"],
            "manni-sulbt": ["bredacorrigan@manning.ie"],
            "dbsch-kbvfz": [
              "shane.moore@pjhegarty.ie",
              "niall.mckenna@pjhegarty.ie",
              "simon.odonoghue@pjhegarty.ie",
              "michael.cooke@pjhegarty.ie",
            ],
          }

          const projectIsNotUserRestricted = () => {
            return !Object.prototype.hasOwnProperty.call(
              userRestrictedProjects,
              projectExid
            )
          }

          const isFromPriviledgedCompany = () => {
            return ["hintongroup.co.uk", "mackinltd.com"].some((companyName) =>
              userEmail.includes(companyName)
            )
          }

          return (
            (projectIsNotUserRestricted() ||
              isAnnotator() ||
              isAdmin() ||
              userRestrictedProjects[projectExid].includes(userEmail) ||
              isFromPriviledgedCompany()) &&
            !isRestricted()
          )
        },
        anpr: () => {
          const userEmail = accountStore.email || ""
          const routeCameraExid = camelizeKeys(route.params)?.cameraExid
          const cameraId =
            useCameraStore().selectedCameraExid || routeCameraExid

          const userRestrictedCameras = {
            "dub10-nzitx": ["construction@evercam.io"],
          }

          const cameraIsNotUserRestricted = () => {
            return !Object.prototype.hasOwnProperty.call(
              userRestrictedCameras,
              cameraId
            )
          }

          return (
            (isAdmin() ||
              cameraIsNotUserRestricted() ||
              userRestrictedCameras[cameraId].includes(userEmail)) &&
            !isRestricted()
          )
        },
        videoWalls: () => {
          return (
            (useAccountStore().hasUserAccessToFeature(
              UserFeatureFlag.VideoWalls
            ) ||
              isQA() ||
              isDev) &&
            !isRestricted()
          )
        },
        sitePlanner: () => {
          return isAdmin()
        },
        massCameraSharing: () => {
          return (
            (useAccountStore().hasUserAccessToFeature(
              UserFeatureFlag.MassCameraSharing
            ) ||
              isQA() ||
              isDev) &&
            !isRestricted()
          )
        },
        copilot: () => {
          return (
            isAdmin() ||
            useAccountStore().hasUserAccessToFeature(UserFeatureFlag.Copilot)
          )
        },
      },
    },
  }

  app.provide("permissions", { user, camera, project })
})
